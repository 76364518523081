@import "styles/Constants.scss";

.sharing-card, .card.sharing-card {
  // background-color: #85FFBD;
  // background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%);
  box-shadow: 0 .5em 2em rgba(0,0,0,.33);

  // background-color: #93d714;
  background: white;
  // background-image: linear-gradient(90deg, #b7e333 0%, #38b03a 100%);
  // background-color: #0093E9;
  background: linear-gradient(34deg, #0093E9 0%, #80D0C7 100%);
  // background-color: #0093E9;

  // linear-gradient(34deg, #0093E9 0%, #80D0C7 100%);

  border-radius: 2em;

  min-height: 10em;

  position: relative;
  overflow: hidden;

  .bg {

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .33;

    background-image: url('https://scontent-lax3-1.xx.fbcdn.net/v/t1.6435-9/161311143_130554679073358_4281200534355770040_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=e3f864&_nc_ohc=iXM0wSKjCLgAX-joc09&_nc_ht=scontent-lax3-1.xx&oh=00_AT9x8jiSt-xQhZBiRhGUL9TEM-X8qTcFKYAMGieo9uZP2A&oe=62860713');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 30%;
    filter: blur(2px);
  }
  .content {
    position: relative;


    .url {
      padding: 1em;
      background: rgba(0,0,0,.2);
      border-radius: 1em;
      color: white;
    }
  }

  // text-shadow: 1px 1px 1px rgba(0,0,0,.15);
}


.sponsor-cont {
  margin-top: 1em;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;

  .sponsor-box2-cont.is-visible {

    .sponsor-box2 {
      // transition: .5s all;
      animation: bounce-4 1s;
      animation-timing-function:cubic-bezier(0.6, -0.28, 0.735, 0.045); 
      // animation-timing-function: ease;
    }

  }

  .sponsor-box2-cont {
    margin-right: .5em;
    margin-bottom: 1em;  
    width: 6em;

    .name {
      text-align: center;
      line-height: .9;
      
    }
    .dollars {
      color: #ffcb00;
      font-weight: 900;
      margin-top: .5em;
      margin-bottom: .25em;
    }
    .sponsor-box2 {
      border-radius: 1em;
      // border: 2px solid rgba(0,0,0,.2);
      background: #ffcb00;
      
      // transition: .5s all;
      // transform: scale(1);
      
      width: 6em;
      height: 6em;
      display: inline-flex;
      align-items: center;
      justify-content: center;
  
      i {
        color: rgba(0,0,0,.33);
        font-size: 3rem;
      }
      
    }
    
  }
  .sponsor-box2-cont.empty {
    .sponsor-box2 {
      border: 4px dashed rgba(0,0,0,.2);
      background: none;

      i {
        color: rgba(0,0,0,.1);
      }
    }
  }



}

@keyframes bounce-4 {
  0%   { transform: scale(1,1)    translateY(0); }
  10%  { transform: scale(1.05,.95) translateY(0); }
  30%  { transform: scale(.95,1.05) translateY(-20px); }
  50%  { transform: scale(1,1)    translateY(0); }
  100% { transform: scale(1,1)    translateY(0); }
}
.banner-slant {
  position: absolute;
  width: 100%;
  
  transform-origin: center;
  height: 400px;
  background: $blue;
  overflow: hidden;
  top: -25px;

  // transition: .3s all;

  .banner-cont {
    
    transform-origin: center;
    height: 150%;
    opacity: .33;

    // transition: .3s all;
  }
}
.banner-slant.start {
  // transform: skewY(-6deg);

  .banner-cont {
    // transform: skewY(6deg);
  }
}

.banner-cont {
  background-color: rgba(0,0,0,.04);
  width: 100%;
  // height: 10rem;
  padding: 1em 0;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  // background: linear-gradient(to bottom, rgba(83, 179, 213, .3) 0%, rgba(0,0,0,0) 100%);
  background: linear-gradient(to bottom, rgba(83, 179, 213, 0.3) 0%, rgba(255, 255, 255, 0.46) 50%, transparent 100%);

  height: 250px;
  z-index: -1;  

  .xp-cont {
    min-width: 600px;
    max-width: 100%;

    margin: 0 1rem;
  }

  
  // background: linear-gradient(to bottom, #252364 0%, #385160 100%);
  // opacity: .3;
}
.banner-cont.bw {
  filter: grayscale(1);
}

.coins-cont {
  padding: .5em;

  transition: .2s all;
  border-radius: 2rem;
  display: inline-block;
}
.coins-cont:hover {
  background: white;
}


  // .inner {
  //   white-space: nowrap;
  //   width: 100%;
  //   overflow: auto;
  //   display: flex;
  //   flex-wrap: nowrap;


  //   .item {
  //     // flex: 0 0 auto;

  //     display: inline-block;
  //   }

    

    
  // }


.desktop-banner-height {
  height: $profile-banner-height;

  @include mobile {
    height: auto;
  }
}

.profile-nav {

  // @include desktop {
  //   position: absolute;
  //   top: $profile-banner-height;
  // }
  height: 61px;
  padding-top: 7px;
  width: 100%;
  margin-bottom: 1em;


  a {
    display: block;
    padding: 1em;
    position: relative;
    // top: 1px;
    color: transparentize($blue, .4);
    // rgba(0,0,0,.33);
    height: 100%;
  }

  a.active {
    // font-weight: bold;
    color: $blue;
    // rgba(0,0,0,.77);

    // border-bottom: 1px solid $blue;
    // rgba(0,0,0,.77);

    i {
      // color: rgba(0,0,0,.77) !important;
      color: $blue !important;
    }
  }

  ul {
    height: 100%;
    // display: flex;
    list-style: none;
    // border-bottom: 1px solid rgba(0,0,0,.1);
    padding: 0;
    margin: 0;

    // overflow-x: auto;
    // overflow-y: hidden;
    
    // @include mobile {
    //   justify-content: center;
    // }

    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;
    

    li {
      height: 100%;
      // padding: 1em;
      // display: flex;
      // align-items: center;
      // text-align: center;
      display: inline-block;
      flex: 0 0 auto;

      i {
        margin-right: .5em;
        font-size: .9em;
        // color: rgba(0,0,0,.5);
        
      }
      span {

      }
    }
  }
}

.profile-info {
  width: 100%;
  max-width: 18rem;
  padding: 0 1rem;
  position: relative;
  text-align: center;

  h1 {
    font-size: 1.3rem;
  }

}

.profile-content {
  h1 {
    margin: 1.5em 0;
    font-size: 1.5rem;
  }
  h1.no-margin-bottom {
    margin-bottom: 0;
  }
  h1.no-margin-top {
    margin-top: 0;
  }
}

.profile-cont {

  

  .profile-content {
    width: 100%;
    // padding: 2em;
    padding-top: 0;
    padding-bottom: 200px;

    @include mobile {
      min-width: 60vw;
    }
    
    
  }

  .page-copy {
    font-size: 17px;
    line-height: 1.5;
  }

  .profile-body {
    padding-top: 2rem;
  }

  
}

.uppy-dd-cont {
  display: inline-block;
  position: relative;

  .uppy-DragDrop-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .hover-overlay {
    opacity: 0;
  }
}
.uppy-dd-cont:hover {


  .hover-overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    font-weight:bold;

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.66);
    color: white;
    pointer-events: none;
    
  }

}

.pledge-bar-cont {
  // height: $profile-banner-height;
  height: 36px;

  @include mobile {
    height: auto;
    padding-top: 0;
  }
}

.profile-right-column {
  
  flex-basis:500px;
  flex-grow:9999;

  
}

.profile-avatar-column {
  
  flex-basis:256px;
  flex-grow:1;

  margin-right: 3em;

  @include mobile {
    margin-right: 0;
  }
  // flex-grow:1;
  // width: 256px;
  // @include mobile {
  //   width: 100%;
  // }
}

.athlete-avatar-cont {
  box-shadow: 0px 1.5rem 2rem rgba(0,0,0,.1);
  font-size: 12em;
  width: 1em;
  height: 1em;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgb(37, 35, 100);
  background-color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;

  i {
    position: absolute;
    font-size: .5em;
    color: rgb(83, 179, 213);
  }
}

.new-avatar-cont {
  // overflow: hidden;
  font-size: 10em;
  width: 1em;
  height: 1em;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgb(37, 35, 100);
  background-color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
  // overflow: hidden;

  i {
    position: absolute;
    font-size: .5em;
    color: rgb(83, 179, 213);
  }

  .uppy-DragDrop-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .hover-overlay {
    opacity: 0;
  }
}

.avatar-cont {
  // overflow: hidden;
  font-size: 10em;
  width: 1em;
  height: 1em;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgb(37, 35, 100);
  background-color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
  overflow: hidden;

  i {
    position: absolute;
    font-size: .5em;
    color: rgb(83, 179, 213);
  }

  .uppy-DragDrop-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .hover-overlay {
    opacity: 0;
  }
}

.avatar-cont:hover {



  .hover-overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    font-weight:bold;

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.66);
    color: white;
    pointer-events: none;
    
  }
}

// .side-nav-button {
//   position: fixed;
//   width: 3rem;
//   height: 3rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 50%;
//   background-color:  transparentize($color:$blue, $amount: 0);
//   bottom: 1rem;
//   left: 1rem;
//   z-index: 900;

//   transition: all .33s;

//   cursor: pointer;
  

//   i {
//     color: white;
//   }
// }

// .side-nav-button.opened {
//   left: calc(#{$side-nav-width} - 1rem);
// }

// .side-nav-button:hover {
//   background-color: $blue;
// }
// .side-nav.opened {
//   @include mobile {
//     left: 0;
//   }
// }

// .side-nav {

// }


.content-cont {
  flex: 1;
  

  .content {
    // margin-left: $side-nav-width;
    // flex: 1;
    // padding: 0 2em;
    // padding-top: 1em;
    margin-bottom: 200px;

    width: 100%;

    h1 {
      margin-top: .25em;
    }

    .narrow-content {
      padding: 0 2em;
    }
  }

  .content.margin {
    margin-left: $side-nav-width;

    @include mobile {
      margin-left: 0;
    }
  }

  
}

