$yellow: #ffb300;
$gold: #f6b904;
$red: #f23800;
// $blue: #53b3d5;
$blue: #0075B3;
$ruby: #a60000;
$orange: #ff6434;
$red: #ec1c23;
$darkBlue: #252364;
$top-nav-height: 65px;
$side-nav-width: 200px;

$profile-banner-height: 225px;

$mobile-width: 599px;
$desktop-width: 600px;

@mixin mobile {
  @media (max-width: $mobile-width) { @content; }
}
@mixin desktop {
  @media (min-width: $desktop-width) { @content; }
}


