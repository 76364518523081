@import "styles/Constants.scss";

.react-calendar {
  background: white;
  border-radius: .5em;
  border: none;
  outline: none;
  padding: 1em;
  box-shadow: 0 .5em .5em rgba(0,0,0,.1);


  button {
    color: $blue;
  }
  .react-calendar__tile--active {
    background: $blue;
    color: white;
  }
  .react-calendar__tile--now {
    border: 2px dashed rgba(0,0,0,.1);
    background: none;
  }
  .react-calendar__tile--now.react-calendar__tile--active  {
    background: $blue;
    color: white;
  }
  .react-calendar__tile--now:enabled:focus {
    background: rgba(0,0,0,.05);
  }
  .react-calendar__tile--now:enabled:hover {
    background: rgba(0,0,0,.1);
  }
}
.react-date-picker {
  width: 100%;
}

.react-date-picker__wrapper {
  border: 2px solid rgba(0,0,0,.1);
  padding: .75em;
  width: 100%;
  box-sizing: border-box;
  transition: all .2s;
  outline: none;
  min-width: 9rem;
  font-size: 1rem;
  width: 100%;
}

input.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  /* height: 100%; */
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}