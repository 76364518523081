@import "styles/Constants.scss";
@import "styles/starburst.scss";
// @font-face {
//   font-family: 'Tofino Wide';
//   src: url('./fonts/TofinoProPersonalWide-Medium.woff2') format('woff2'),
//       url('./fonts/TofinoProPersonalWide-Medium.woff') format('woff');
//   font-weight: 500;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'Tofino Wide';
//   src: url('./fonts/TofinoProPersonalWide-Bold.woff2') format('woff2'),
//       url('./fonts/TofinoProPersonalWide-Bold.woff') format('woff');
//   font-weight: 700;
//   font-style: normal;
// }
// @font-face {
//   font-family: 'Tofino';
//   src: url('./fonts/TofinoProPersonal-Regular.woff2') format('woff2'),
//       url('./fonts/TofinoProPersonal-Regular.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
.club-home-cont {
  // margin-top: $top-nav-height;
  margin-top: 100px;
}
.avatar-shadow {
  box-shadow: 0px 1.5rem 2rem rgba(0,0,0,.1);
}
.uni-banner {
  background: rgb(203, 240, 255);
  position: absolute;
  left: $side-nav-width;
  top: $top-nav-height;
  height: $profile-banner-height;
  width: calc(100% - 200px);

  @include mobile {
    left: 0;
    width: 100%;
  }
}

.rounded-box {
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 2em;
  
}

td.fit {
  width: 1%;
}

.white-bar.gradient-2 {
  background-color: #0093E9 !important;
  background-image: linear-gradient(#ffffff, rgb(255, 255, 255) 30.25%, #96ebff) !important;
}

.gradient-2 {
  box-shadow: 0 .5em 2em rgba(0,0,0,.33) !important;

  background-color: #0093E9 !important;
  background-image: linear-gradient(34deg, #0093E9 0%, #80D0C7 100%) !important;
}

.cool-gradient, .card.cool-gradient {
/* Created with https://www.css-gradient.com */

  // background: linear-gradient(to left, #CC7B25 0%, #DE168C 100%);

  box-shadow: 0 .5em 2em rgba(0,0,0,.33);

  background-color: #0093E9;
  background-image: linear-gradient(34deg, #0093E9 0%, #80D0C7 100%);


  border-radius: 2em;
  color: white;
  text-align: left;
  // font-weight: 200;
  margin: 4em 0;
}

.hover-shadow {
  box-shadow: none;
  transition: .2s box-shadow;
}
.hover-shadow:hover {
  box-shadow: 0 0 1em rgba(0,0,0,.1);
  transition: .2s box-shadow;
}
.chart-tooltip {
  background: white;
  padding: .5rem;
  border-radius: .5rem;
}


.border-bottom {
  border-bottom: 1px solid rgba(0,0,0,.05)
}
.border-right {
  border-right: 1px solid rgba(0,0,0,.1)
}
.border-left {
  border-left: 1px solid rgba(0,0,0,.05)
}
.border-top {
  border-top: 1px solid rgba(0,0,0,.05)
}
.font-weight-100 {
  font-weight: 100;
}
.font-weight-200 {
  font-weight: 200;
}
.font-weight-300 {
  font-weight: 300;
}
.font-narrow {
  letter-spacing: -.05em;
}

.circle-cont {
  border-radius: 50%;
  overflow: hidden;
  display: inline-flex;
  width: 1em;
  height: 1em;
  background-color: rgba(0,0,0,.05);
  align-items: center;
  justify-content: center;

  i {
    font-size: .5em;

  }

  img {
    width: 100%;
    height: auto;
  }
}

// }
.inner-label {
  position: absolute;
  right: 1em;
  color: gray;
  display: inline-block;
  pointer-events: none;
}
.inner-label.right {
  right: .7em;
}
.inner-label.right.number {
  right: 2em;
}
.inner-label.left {
  left: .7em;
}


.shadow {
  box-shadow: 0 .1em 1em rgba(0,0,0,.33) !important; 
}
.red-text {
  color: $red;
}
.lightgray-text {
  color: lightgray;
}
.gray-text {
  color: gray;
}


table.leaderboard {

  tr:nth-child(1) {
    td:first-of-type {
      .rank {
        background: gold;
      }
    }
  }
  tr:nth-child(2) {
    td:first-of-type {
      .rank {
        // background: silver;
      }
    }
  }
  tr:nth-child(3) {
    td:first-of-type {
      .rank {
        // background: #CD7F32;
      }
    }
  }
  tr {
    td:first-of-type {
      .rank {
        width: 1.5em;
        height: 1.5em;
        border-radius: 1.5em;
        background: rgba(0, 0, 0, 0);
        color: rgba(0,0,0,.4);
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.autocomplete {
  position: relative;
  
  .menu-cont {
    transition: .2s all;
    border-radius: .5em;
    cursor: pointer;
    position: relative;
  
    .menu {
      position: fixed;
      // top: 100%;
      // left: 0;
      padding: .25em;
      border-radius: .5em;
      background: white;
      box-shadow: 0 .1em 1em rgba(0,0,0,.33);
      max-height: 20em;
      overflow-y: auto;
      z-index: 99999;

      .item.highlighted {
        background: rgba(0,0,0,.05);
      }

      .item {
        white-space: nowrap;
        padding: .5em;
        transition: .2s all;
        border-radius: .5em;
        cursor: pointer;
      }
      .item:hover {
        background: rgba(0,0,0,.1);
      }
    }
  }
}

table.vertical {
  border-collapse: collapse;

  tr {
    th, td {
      padding: .5em;
    }
    th {
      text-align: right;
      font-weight: bold;
      width: 1%;
      white-space: nowrap;
      background: rgba(0,0,0,.05);
      // border-right: 2px solid rgba(0,0,0,.05);
      border-bottom: 1px solid rgba(0,0,0,.05);
    }
    td {
      font-weight: normal;
      border-bottom: 1px solid rgba(0,0,0,.05);
    }
  }
  tr:last-child {
    th, td {
      border-bottom: none;
    }
  }
}

.dash-week-cont {
  border: 1px solid lightgray;
  border-radius: 1rem;
  .day-cont:first-of-type {
    border-left: none;
  }
  .day-cont {
    border-left: 1px solid rgba(0,0,0,.05);
    padding: .5rem;
    .dates {
      color: gray;
    }
    
    .dash-day {

      .event {
        padding: 2px;
        border-radius: 4px;
        background: rgba(0,0,0,.05);
        margin-bottom: 2px;
        font-size: .8em;

      }
      .clickable-event {
        transition: .2s all;
        transform: scale(1);
        cursor: pointer;
      }
      .clickable-event:hover {
        transition: .2s all;
        transform: scale(1.1);
        background: rgba(0,0,0,.01);
        // box-shadow: 0 .1em .5em rgba(0,0,0,.1);

      }
      
      // border-left: none;
      // border-right: 1px solid lightgray;
      padding: .5rem;
      flex: 1;
      height: 6em;
      overflow-y: auto;
    }
    .dash-day:first-of-type {
      // border-left: 1px solid lightgray;
      
    }
    .dash-day:last-of-type {
      // border: none;
    }
  }
  

 
}

.multi-select {
  padding: .5em .8em;
  margin: .25em;
  border: 2px solid lightgray;
  // background: lightgray;
  font-weight: bold;
  display: inline-block;
  border-radius: 5px;
  color: gray;
}
.multi-select.selected {
  background: $darkBlue;

  border: 2px solid $darkBlue;
  color: white;
}

.page-banner {
  height: 250px;
  width: 100%;
  background-size: cover;
  background-color: rgba(0,0,0,.1);
  background-position: center;
  background-repeat: no-repeat;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -3px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 3px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
// .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
//   // background-color: grey;
//   margin-bottom: 1.5rem;
// }


.masonry {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-template-rows: masonry;


  
}
.inspo-card {
  // background: red;
  position: relative;

  img {
    width: 100%;
  }
  .title {

  }
}

.inspo-cont.expanded {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99999;
  transition: all 1s;
}
.inspo-cont-outer:hover {
  .inspo-cont {
    .tools {
      opacity: 1;
      transition: all .2s;
    }
    .glow {
      opacity: 1;
      transition: all .2s;

    }
    .title {
      opacity: 1;
      transition: all .2s;
      bottom: .5rem;
    }
  }
}

.inspo-cont-outer {
  // background-color: red;
  margin-bottom: 1.5em;

  .static-title {
    font-weight: bold;
    font-size: 1.25rem;
    padding-left: .5em;
    padding-top: .5em;
  }
  
  .inspo-cont {
    padding: 1rem;
    


    transition: all 1s;
    
    // width: 100%;
    // cursor: pointer;
    max-width: 300px;
    // height: 300px;
    min-height: 100px;
    margin: .5rem;
    vertical-align: top;
    display: inline-block;
    // max-width: 100%;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    background-position: center;
    background-size: cover;
    // background-color: rgba(161, 98, 98, 0.01);
    // background-color: rgba(0,0,0,.001);
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    
    overflow: hidden;
    background-color: rgba(255,255,255,.25);

    .tools {
      opacity: 0;
      transition: all .2s;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      top: .5rem;
      

      .tool {
        border-radius: 50%;
        background: white;
        box-shadow: 0 10px 10px rgba(0,0,0,.2);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .7em;
        margin: 4px;
        cursor: pointer;
      }
    }

    .dummy {
      width: 100%;
      opacity: 0;
      pointer-events: none;
    }

    .glow {
      opacity: 0;
      transition: all .2s;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(to bottom, transparent, rgba(0,0,0,.1));
      pointer-events: none;
    }

    .title {
      opacity: 0;
      transition: all .2s;
      bottom: 0;
      position: absolute;
      font-size: 1.5rem;
      font-weight: 900;
      color: white;
      text-shadow: 0 0 .5em rgba(0,0,0,.5);
    }
  }
}


.andy-cont {
  // background: rgba(0, 255, 0, 0.384);

  // width: 200px;
  // height: 200px;
  width: 100%;
  padding-top: 100%;
  position: relative;
  z-index: 0;
  // margin: calc(200px * .25);
  // height: 200px;

  svg, .svg {
    width: 100%;
    // height: auto;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 1000;
    // width: 150%;
    // top: -25%;
    // left: -25%;
    pointer-events: none;
    //width: 100px;
  }
}

.team-avatar-cont {
  // background: lightblue;
  overflow: visible;

  width: 40px;
  height: 60px;
  display: inline-block;
  // margin: 1em;


  .team-avatar-cont-inner {
    position: relative;
  }
  .team-avatar-cont-svg {
    position: relative;
    z-index: 999;
    display: inline-block;

  }
}

.select-profile {
  .profile-card-cont2:hover {
    
    // transition: .2s all;


    .profile-circle {
      box-shadow: 0 .5em 1em rgba(0,0,0,.1);
      transition: .2s all;
      transform: scale(1.1) translateY(-1em);
    }
    .name {
      transition: .2s all;
      
    }

    
  }


  .profile-card-cont2 {
    
    // background: lime;
    display: inline-block;
    text-align: center;
    padding: 1em;

    .profile-circle {
      transition: .2s all;
      transform: scale(1);
      box-shadow: none;
      transition: .2s all;
      background: white;
      width: 10em;
      height: 10em;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .name {
      font-size: 1.5rem;
      font-weight: bold;
      color: $blue;
      transition: .2s all;
      transform: scale(1);
    }
    

    i {
      color: $blue;
      font-size: 5em;
    }


  }

}

.team-portraits {
  // position: absolute;
  // top: 100px;
  // left: 300px;
  // z-index: 100;

  

  .profile-card-cont {
    width: 6em;
    height: 6em;

    cursor: pointer;

    .profile-card-color {
      background: linear-gradient(to bottom, magenta 0%, red 100%);
    }

    .profile-card-name {
      color: white;
      font-weight: bold;
      font-size: .75em;
      position: absolute;
      bottom: 0;
      padding: 1em;
      // height: 100%;
      width: 100%;
      text-align: center;
      background: linear-gradient(to top, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 100%)
    }
  }
}

.profile-card-cont:hover {
  transition: .33s all;
  box-shadow: 0 .5em 1em rgba(0,0,0,.5);
  transform: scale(1.1);
  

  .profile-card-color {
    transition: .33s opacity;
    opacity: 1;
  }

}

.profile-card-cont.parent-card {
  .profile-card-color {
    background: rgba(0,0,0,.8);
  }
  
}

.profile-card-cont:nth-of-type(2) {
  .profile-card-color {
    background: linear-gradient(to bottom, magenta 0%, red 100%);
  }
}
.profile-card-cont:nth-of-type(3) {
  .profile-card-color {
    background: linear-gradient(to bottom, cyan 0%, blue 100%);
  }
}

.profile-card-cont {
  transition: .33s all;
  width: 12em;
  height: 12em;
  border-radius: 2em;
  overflow: hidden; 
  position: relative;
  display: inline-block;
  box-shadow: none;
  margin: .5em;

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);

  .profile-card-color {
    transition: .33s all;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, yellow 0%, green 100%);
    position: absolute;
    left: 0;
    top: 0;
    opacity: .85;
  }
  .profile-card-image {
    background-position: center;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .profile-card-name {
    color: white;
    font-weight: bold;
    font-size: 1.3em;
    position: absolute;
    bottom: 0;
    padding: 1em;
    // height: 100%;
    width: 100%;
    text-align: center;
    background: linear-gradient(to top, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 100%)
  }

  i {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4em;
    position: absolute;
    left: 0;
    top: -.25em;
    color: white;
  }
}
.mobile-only {
  @include desktop {
    display: none !important;
  }
}
.desktop-only {
  @include mobile {
    display: none !important;
  }
}

.top-banner {
  position: relative;
  // left: 0;
  // right: 0;
  // top: $top-nav-height;
  padding: 1em;

  background: #e6e6e6;
  color: #5b5b5b;
  text-align: center;
  width: 100%;

}

table.basic {
  border-collapse: collapse;
  tr {

  }
}

main {
  background: rgba(0,0,0,.05);
}

.white-text {
  color: white;
}

.flex-grid {
  
  .grid-item {
    margin: .5em; 

    min-width: 300px;

    .number-label {
      color: gray;
      font-weight: bold;
      
    }
  }
}

.lap-test {
  animation: laptest 3s;
  display: inline-block;
  // margin-bottom: .66em;
  padding: .66em;

  border-radius: 999px;
  position: relative;
  z-index: 999;
}
// .lap-test {
//   font-size: 1rem;
//   transition: all 1s;
// }
// .lap-test.start {
//   font-size: 2rem;
//   transition: all 1s;
// }
@keyframes laptest {
  0%    { opacity: 0; padding: 0 .66em; background: transparent; transform: scale(1.0); }
  15%   { opacity: 1; padding: .66em; background: white; transform: scale(1.05);  }
  85%   { padding: .66em; background: white; transform: scale(1.05);  }
  100%  { padding: .66em; background: transparent;  transform: scale(1);}
}
// @keyframes laptest {
//   0%    { font-size: 1rem }
//   50%   { font-size: 1.25rem }
//   100%  { font-size: 1rem }
// }
// @keyframes laptest {
//   0%    { transform: scale(0); }
//   50%   { transform: scale(0); }
//   100%  { transform: scale(1); }
// }

.invisible {
  opacity: 0;
}
.faded {
  opacity: .1;
}

.grid-layout2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 3fr));
  grid-auto-rows: minmax(50px, auto);
  grid-gap: 1.5em;
  grid-auto-flow: dense;

  .grid-item {
    grid-column-end: span 3;
    grid-row-end: span 1;

    @include mobile {
      grid-column-end: span 3 !important;
    }
  }
  .box {
    // width: 150px;
    
   background: white;
   color: rgba(0,0,0,.66);
   // margin: 0 1rem 1rem 0;
   // display: inline-block;
   // width: 100%;
   text-align: center;
   // font-family: system-ui;
   // font-weight: 900;
   font-size: 1rem;
   border-radius: 2em;

   display: inline-flex;
   position: relative;
   transition: all .2s;
   transform-style: preserve-3d;

   cursor: pointer;
   padding: 2em;
   background: rgba(255,255,255,.75);  
   position: relative;
   box-shadow: 0 0 .1em rgba(0,0,0,.1);
   border-radius: 2em;

   i {
    font-size: 4em;
    color: $blue;
    // opacity: .5;
  }

  .number {
    font-weight: 300;
    font-size: 3rem;
  }

  .number-label {
    font-size: 1em;
    color: rgba(0,0,0,.33);
    font-weight: 900;
  }
  .number-label.white-text {
    color: rgba(255,255,255, .4);
  }


   .box-face {
     position: absolute;
     width: 100%;
     height: 100%;
     // color: white;
     -webkit-backface-visibility: hidden;
     backface-visibility: hidden;

     // border-radius: 2em;
     border-radius: 2rem;
     align-items: center;
     justify-content: center;
     display: flex;
     flex-direction: column;
     
    }
  }
  .grid-item.span-1-h {
    grid-column-end: span 1;
    // grid-row-end: span 1;
  }
  .grid-item.span-1-v {
    // grid-column-end: span 1;
    grid-row-end: span 1;
  }
  .grid-item.span-3-v {
    // grid-column-end: span 1;
    grid-row-end: span 3;
  }

  .grid-item.span-6-h {
    grid-column-end: span 6;

    @include mobile {
      grid-column-end: span 3;
    }
    // grid-row-end: span 2;
  }
  .grid-item.span-2-v {
    // grid-column-end: span 2;
    grid-row-end: span 2;
  }
  
  .grid-item.span-3-h {
    grid-column-end: span 3;

    @include mobile {
      grid-column-end: span 3;
    }
    // grid-row-end: span 2;
  }
  .grid-item.span-3-v {
    // grid-column-end: span 2;
    grid-row-end: span 3;
  }

  .grid-item.span-4-h {
    grid-column-end: span 4;
    // grid-row-end: span 2;
  }
  .grid-item.span-4-v {
    // grid-column-end: span 2;
    grid-row-end: span 4;
  }
  .grid-item.span-auto-v{
    // grid-column-end: span 2;
    //  grid-row-end: auto;
  }
}

.grid-layout {
  // display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(100px, 3fr));
  // grid-auto-rows: minmax(50px, auto);
  // grid-gap: 1.5em;
  // grid-auto-flow: dense;




  display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-auto-rows: minmax(150px, auto);
  // repeat(auto-fit, minmax(110px, .5fr));
  grid-gap: 1.5em;
  
  grid-auto-flow: dense;


  // grid-template-columns: repeat(3, 1fr);
  // grid-auto-rows: 200px;
  // grid-gap: 1.5em;

  .grid-item {
    grid-column-end: span 2;
    grid-row-end: span 2;

    padding: 1rem;
    font-size: 14px;
    // font-weight: bold;
    // text-transform: uppercase;
    color: #929796;
    background-color: #333;
    border-radius: 5px;

    @include mobile {
      grid-column-end: span 6 !important;
    }


    &:nth-child(odd) {
        // background-color: #424242;
    }

    .bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.6);
      border-radius: inherit;
      background-size: cover;
      background-position: center;
    }

    .bg-color {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(0,0,0,.1) 0%, rgba(0, 0, 0, 0.6) 100%);
      border-radius: inherit;
    }

  }

  .grid-item.dark {
    color: white;

    .number-label {
      color: lightgray;
    }
  }

  .grid-item.span-1-h {
    grid-column-end: span 1;
    // grid-row-end: span 1;
  }
  .grid-item.span-1-v {
    // grid-column-end: span 1;
    grid-row-end: span 1;
  }

  .grid-item.span-2-h {
    grid-column-end: span 2;
    // grid-row-end: span 2;
  }
  .grid-item.span-2-v {
    // grid-column-end: span 2;
    grid-row-end: span 2;
  }
  
  .grid-item.span-3-h {
    grid-column-end: span 3;
    // grid-row-end: span 2;
  }
  .grid-item.span-3-v {
    // grid-column-end: span 2;
    grid-row-end: span 3;
  }

  .grid-item.span-4-h {
    grid-column-end: span 4;
    // grid-row-end: span 2;
  }
  .grid-item.span-6-h {
    grid-column-end: span 6;
    // grid-row-end: span 2;
  }
  .grid-item.span-4-v {
    // grid-column-end: span 2;
    grid-row-end: span 4;
  }
  .grid-item.span-auto-v{
    // grid-column-end: span 2;
     grid-row-end: auto;
  }

  .box.flipped {
    transform: rotateX(180deg) scale(1.1);
  }

  .box.flippable {
    transition: all 1s;
  }

  .box:not(.no-hover):not(.flipped):hover {
    box-shadow: .2em .2em 2em rgba(0,0,0,.1);
    // transition: .2s all;
    z-index: 1;
    // transform: scale(1.1) perspective(500px) rotateX(4deg);
  }

  .box:not(.no-hover).flipped:hover {
    // transform: scale(1.1) perspective(200px) rotateX(18deg);
  }

  // .box:not(.flipped):hover {
  //   box-shadow: .2em .2em 2em rgba(0,0,0,.1);
  //   // transition: .2s all;
  //   z-index: 1;
  //   transform: scale(1.1);
  // }

  .box.no-click {
    cursor: initial;
  }

  .box {
     // width: 150px;
     
    background: white;
    color: rgba(0,0,0,.66);
    // margin: 0 1rem 1rem 0;
    // display: inline-block;
    // width: 100%;
    text-align: center;
    // font-family: system-ui;
    // font-weight: 900;
    font-size: 1rem;
    border-radius: 2em;

    display: inline-flex;
    position: relative;
    transition: all .2s;
    transform-style: preserve-3d;

    cursor: pointer;
    padding: 2em;
    background: rgba(255,255,255,.75);  
    position: relative;
    box-shadow: 0 0 .1em rgba(0,0,0,.1);
    border-radius: 2em;


    .box-face {
      position: absolute;
      width: 100%;
      height: 100%;
      // color: white;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;

      // border-radius: 2em;
      border-radius: 2rem;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      
    }

    .box-face-back {
      background: rgb(60,60,60);
      transform: rotateX(180deg);
      color: white;

      

    }

    .box-face-front {
      // border-radius: 2em;

    }

    i {
      font-size: 4em;
      color: $blue;
      // opacity: .5;
    }

    .number {
      font-weight: 300;
      font-size: 3rem;
    }

    .number-label {
      font-size: 1em;
      color: rgba(0,0,0,.33);
      font-weight: 900;
    }
    .number-label.white-text {
      color: rgba(255,255,255, .4);
    }


    .track {
      width: 100%;
      height: 6rem;

      border-radius: 9999px;
      border: 10px double $blue;
    }
  } 



  .box.night {
    background-color: rgba(0,0,0,.9);

    // background-color:black;
    background-image:
    radial-gradient(white, rgba(255,255,255,.2) 2px, transparent 5px),
    radial-gradient(white, rgba(255,255,255,.15) 1px, transparent 1px),
    radial-gradient(white, rgba(255,255,255,.1) 2px, transparent 1px),
    radial-gradient(rgba(255,255,255,1), rgba(255,255,255,.1) 2px, transparent 10px);
    background-size: 150px 150px, 150px 150px, 150px 150px, 150px 150px; 
    background-position: 0 0, 40px 60px, 130px 270px, 70px 100px;

    .fas {
      font-size: 6em;
    }

    

    .moon {
      width: 6em;
      display: flex;
      align-items: center;
      justify-content: center; 
      height: 6em;
      
      border-radius: 50%;
      
      background: wheat;
      
          background-position-x: 0%;
          background-position-y: 0%;
          background-image: none;
          background-size: auto;
      
      background-image: radial-gradient(circle, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 5%, transparent 6%), radial-gradient(circle, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 50%, transparent 51%), radial-gradient(circle, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 25%, transparent 26%), radial-gradient(circle, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 5%, transparent 6%);
      
      background-size: 10% 10%, 50% 50%, 50% 75%, 90% 33%;
      
      background-position: 10% 10%, 50% 20%, 50% 75%, 90% 33%;
      
      box-shadow: 0 0 4em #fffacda3;


    }

    .earth {
      width: 9em;
      display: flex;
      align-items: center;
      justify-content: center; 
      height: 9em;
      
      border-radius: 50%;
      
      background: #0064ff;;
      
          background-position-x: 0%;
          background-position-y: 0%;
          background-image: none;
          background-size: auto;


      $green: lightgreen;
      
      background-image: radial-gradient(circle, $green 0%, $green 5%, transparent 6%), radial-gradient(circle, $green 0%, $green 50%, transparent 51%), radial-gradient(circle, $green 0%, $green 25%, transparent 26%), radial-gradient(circle, $green 0%, $green 5%, transparent 6%);
      
      background-size: 10% 10%, 50% 50%, 50% 75%, 90% 33%;
      
      background-position: 10% 10%, 50% 20%, 50% 75%, 90% 33%;
      
      box-shadow: 0 0 4em #fffacda3;


    }
  }
}


.lvl-label {
  font-size: .75em;
  opacity: .6;
  font-weight: bold;
}

.ruby, .stone.ruby {
  color: $ruby;
}

.bar-cont.new {
	height: 14px;
	border-radius: 99999px;
	padding: 0px;
	// background: rgba(43, 101, 121, 0.1098039216);
  background: rgba(0, 0, 0, 0.12);
  // rgb(223, 244, 252);
  // rgb(177, 217, 234);
	width: 100%;
	box-shadow: inset -3px 3px 6px 0px rgba(0, 0, 0, 0.05);
	// overflow: visible;
	position: relative;
	min-width: 100px;
	// background: rgba(83, 179, 213, 0.1);
  box-shadow: none;
  .bar {
    box-shadow: none !important;
  }
}

.bar-cap-cont {
  position: absolute;
  // left: 0;
  // top:0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // z-index: 99999;
  transition: all 2s;
  // padding-right: 18px;
  // overflow: hidden;
  // background:rgba(0,0,0,.1);
  // top: 0;
  // bottom: 0;

  .bar-cap {
    border-radius: 50%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow:  0px .25em 1em  rgba(0,0,0,0.25);

    i {
      font-size:1.5em;
      color: $blue;
    }
  }

}

.bar-cont.new {
  .ddd {
    position: absolute;
    left: 0;
    display: inline-flex;
    right: auto;

    
    
    .right-bar-label-cont {
      position: absolute;
      left: calc(100% + 2em);
      white-space: nowrap;
      transition: all 2s;
    }

    .right-bar-label-cont.end {
      left: auto;
      right: calc(1em + 25px);
      transition: all 2s;
    }

  }
}

.bar-cont {
  height: 14px;
  border-radius: 99999px;
  padding: 0px;
  // background: #2b65791c;
  width: 100%;
  box-shadow: inset 0px 4px 5px 0px rgba(0,0,0,0.15);
  overflow: hidden;
  position: relative;
  min-width: 100px;

  

  

  .ddd {
    // background: rgba(0, 255, 0, 0.345);
    overflow: visible;
    height: 100%;
    position: absolute;
    padding-left: 1em;
    padding-right: 1em;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: all 2s;
  }


  .right-bar-label-cont {

    // position: absolute;
    // top: 10px;
    // right: 1em;
    color: white;
    font-weight: bold;
    text-shadow: 0px 0px 1px rgba(0,0,0,.1);
    font-size: 1rem;  display: flex;
    align-items: center;
    justify-content: center;

    .right-bar-label {

    }
  }

    
  

  .bar-label-cont {
    color: rgba(255,255,255, 1);
    // text-shadow: 0 1px 1px rgba(0,0,0,.5);
    position: absolute;
    z-index: 999;
    font-weight: bold;
    width: 100%;
    height: 100%;
    // background-color: lime;
    text-align: center;
      display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    // text-transform: uppercase;

    .bar-label {

    }
  }

  .bar {
    border-radius: 99999px;
    background: $blue;
    // height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // transition: all 2s;

    // border-radius: 99999px;

    height: 100%;
    transition: all 2s;
    max-width: 100%;
    // background: 
    //   linear-gradient(0deg, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, .25) 97%, rgba(0, 0, 0, 0) 100%),
    //   linear-gradient(to top, #53b3d5 0%, transparent 40%, transparent 100%);
    // background-color: #53b3d5;

    // background: linear-gradient(to right, $darkBlue 0%, $blue 100%);
  }

  .bar.ruby {

    // background: linear-gradient(to right, darkred 0%, red 100%);
    background: linear-gradient(to right, #a3ff00 0%, yellow 100%);
    
    // background: 
    //   linear-gradient(0deg, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, .5) 97%, rgba(0, 0, 0, 0) 100%),
    //   linear-gradient(to top, red 0%, transparent 40%, transparent 100%);

    //   background-color: $ruby;
  }
}



// .mason {
//   columns: 6 200px;
//   column-gap: 1rem;

//   .grass.box {
//     background: DarkSeaGreen;
//   }

//   .box {
//     width: 150px;
//     background: white;
//     color: rgba(0,0,0,.66);
//     margin: 0 1rem 1rem 0;
//     display: inline-block;
//     width: 100%;
//     text-align: center;
//     font-family: system-ui;
//     font-weight: 900;
//     font-size: 2rem;
//     border-radius: 1em;

//     display: inline-flex;
//     align-items: center;
//     justify-content: center;  
//   } 

//   .box.night {
//     background-color: rgba(0,0,0,.9);

//     // background-color:black;
//     background-image:
//     radial-gradient(white, rgba(255,255,255,.2) 2px, transparent 5px),
//     radial-gradient(white, rgba(255,255,255,.15) 1px, transparent 1px),
//     radial-gradient(white, rgba(255,255,255,.1) 2px, transparent 1px),
//     radial-gradient(rgba(255,255,255,1), rgba(255,255,255,.1) 2px, transparent 10px);
//     background-size: 150px 150px, 150px 150px, 150px 150px, 150px 150px; 
//     background-position: 0 0, 40px 60px, 130px 270px, 70px 100px;

//     .fas {
//       font-size: 3em;
//     }

//     .moon {
//       width: 4em;
//       display: flex;
//       align-items: center;
//       justify-content: center; 
//       height: 4em;
      
//       border-radius: 50%;
      
//       background: wheat;
      
//           background-position-x: 0%;
//           background-position-y: 0%;
//           background-image: none;
//           background-size: auto;
      
//       background-image: radial-gradient(circle, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 5%, transparent 6%), radial-gradient(circle, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 50%, transparent 51%), radial-gradient(circle, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 25%, transparent 26%), radial-gradient(circle, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 5%, transparent 6%);
      
//       background-size: 10% 10%, 50% 50%, 50% 75%, 90% 33%;
      
//       background-position: 10% 10%, 50% 20%, 50% 75%, 90% 33%;
      
//       box-shadow: 0 0 2em #fffacda3;

//     }
//   }
//   @for $i from 1 through 36 { 
//     .box:nth-child(#{$i}) {
//       $h: (random(100) + 200) + px;
//       height: $h;
//       //line-height: $h;
//     }
//   }
// }

// .sub-nav {
//   .nav-item {
//     a {
//       color: $darkBlue;
//       font-weight: bold;
//     }
//   }

//   border-bottom:
// }

.sub-nav {

  // @include desktop {
  //   position: absolute;
  //   top: $profile-banner-height;
  // }
  height: 61px;
  padding-top: 7px;
  width: 100%;
  margin-bottom: 1em;


  a {
    display: block;
    padding: 1em;
    position: relative;
    // top: 1px;
    color: rgba(0,0,0,.33);
    height: 100%;
  }

  a.active {
    // font-weight: bold;
    color: rgba(0,0,0,.77);

    border-bottom: 1px solid rgba(0,0,0,.77);

    i {
      color: rgba(0,0,0,.77) !important;
    }
  }

  ul {
    height: 100%;
    // display: flex;
    list-style: none;
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding: 0;
    margin: 0;

    // overflow-x: auto;
    // overflow-y: hidden;
    
    // @include mobile {
    //   justify-content: center;
    // }

    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;
    

    li {
      height: 100%;
      // padding: 1em;
      // display: flex;
      // align-items: center;
      // text-align: center;
      display: inline-block;
      flex: 0 0 auto;

      i {
        margin-right: .5em;
        font-size: .9em;
        color: rgba(0,0,0,.5);
        
      }
      span {

      }
    }
  }
}



.global-error-cont {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000000;

  .error {
    padding: 1em 2em;
    margin-bottom: 1em;
    background: #bf2c2c;
    color: white;
    box-shadow: 0 0.5em .5em rgba(0, 0, 0, 0.19);
    border-radius: 999px;


    
  }
}


* {
  box-sizing: border-box;
}

body {
  // font-family: 'Tofino';
  background-color: rgba(0,0,0,.05);
  // width: 100vw;
  // overflow-x: hidden;
}

.bebas {
  font-family: 'Bebas Neue', sans-serif;
}

h1 {
  // font-family: 'Tofino Wide';
  font-size: 2rem;
  // font-family: 'Bebas Neue', sans-serif;
  // font-style: italic;
  // font-size: 3em;
  color: $blue;
  

  @include mobile {
    font-size: 2rem;
  }
}
h2 {
  // font-family: 'Tofino Wide';
  font-weight: 900;

  @include mobile {
    font-size: 1.1rem;
  }
}

p {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.3;
}
// h1.page-header {
//   margin-top: 1em;
// }
.icon-cont {
  padding: 4px;
  cursor: pointer;
  opacity: .5;
  transition: all .2s; 
  width: 1.5em;
  height: 1.5em;
  line-height: 1.66em;
  text-align: center;
  display: inline-block;
}

.text-left {
  text-align: left;
}

.icon-cont:hover {
  opacity: 1;
  background: rgba(0,0,0,.05);
}
.text-link::before {
  content: "";
  position: absolute;
  top: -.5em;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom: 1em solid rgba(255, 179, 0, 0);
  box-sizing: border-box;
  background: transparent;
  z-index: -1;
  transition: .2s all;
}

.inline-block {
  display: inline-block;
}
.inline-flex {
  display: inline-flex;
}

.text-link {
  // font-family: 'Tofino Wide';
  font-weight: 700;
  font-size:.99rem;
  cursor: pointer;
  // line-height: 1;
  // padding: 4px;
  transition: .2s all;
  border-bottom: none;
  position: relative;
  color: $blue;
}
.text-link:hover {
  color: black !important;
}

.text-link:hover::before {
  // border-bottom: 1em solid transparentize($blue, .75);
}

svg {
  display: block;
}



.avatar.ring-cont {
  .ring {
    transform: rotate(-225deg);
    position: relative;
  }
}


.ring-cont {
  position: relative;
  text-align: center;

  circle {
    transition: 2s stroke-dashoffset;
  }
  circle.init {
    stroke-dashoffset: 0;
  }


  .ring {
    transform: rotate(-90deg);
    position: relative;
  }

  .pct-cont {
    position: absolute;
    top: 31%;
    left: 0;
    right: 0;

    .pct {
      line-height: 1;
      font-size: 2em;
      font-weight: 100;
      letter-spacing: -2px;
    }

    .symbol {
      color: gray;
    }
  }

  .label {
    // position: absolute;
    // bottom: 5%;
    // width: 100%;
    font-weight: bold;
    position: relative;
    top: -.5em;
  }

}


.parent {
  display: flex;
  flex-direction: column;
}
main {
  min-height: 100vh;
  padding-top: $top-nav-height;
  padding-bottom: 200px;
}

footer {
  background: rgba(0,0,0,.1);
  min-height: 100px;

  padding: 2em;

  padding-left: $side-nav-width !important;

  @include mobile {
    padding: 1rem;
    padding-left: 10px !important;
  }
  // margin-left: 2rem;

  // width: 100vw;
}

.full {
  width: 100%;
}
.full-height {
  height: 100%;
}

.font-wide {
  // font-family: 'Tofino Wide';
}
.font-05 {
  font-size: .5rem;
}
.font-075 {
  font-size: .75rem;
}
.font-08 {
  font-size: .8rem;
}
.font-09 {
  font-size: .9rem;
}
.font-1 {
  font-size: 1rem;
}
.font-1-15 {
  font-size: 1.15rem;
}
.font-1-25 {
  font-size: 1.25rem;
}
.font-1-5 {
  font-size: 1.5rem;
}
.font-1-75 {
  font-size: 1.75rem;
}
.font-2 {
  font-size: 2rem;
}
.font-2-5 {
  font-size: 2rem;
}
.font-2-75 {
  font-size: 2rem;
}
.font-3 {
  font-size: 3rem;
}
.font-4 {
  font-size: 4rem;
}

.font-5 {
  font-size: 5rem;
}
.font-6 {
  font-size: 6rem;
}
.font-7-5 {
  font-size: 7.5rem !important;
}
.font-10 {
  font-size: 10rem;
}
.font-11 {
  font-size: 11rem;
}
.font-12 {
  font-size: 12rem;
}
.font-13 {
  font-size: 13rem;
}
.font-14 {
  font-size: 14rem;
}
.font-15 {
  font-size: 15rem;
}
.font-20 {
  font-size: 20rem;
}
.font-25 {
  font-size: 25rem;
}
.font-30 {
  font-size: 30rem;
}

.DayPickerInput-Overlay {
  z-index: 999 !important;
}

.mobile-full {
  @include mobile {
    width: 100%;
  }
}


ul.list {
  list-style: none;
  padding: 0;
  margin: 0;

  a:last-child li {
    border-bottom: none;
  }


  li {
    display: flex;
    align-items: center;
    padding: 1em;
    border-bottom: 1px solid rgba(0,0,0,.05);
    
  }

  li:hover {
    // background: $blue;
    // color: white;
    // font-weight: bold;
  }

}


ul.search {
  list-style: none;
  padding: 0;
  margin: 0;
  // background: white;

  // border-bottom-right-radius: 20px;
  // border-bottom-left-radius: 20px;

  // max-height: 20em;
  // overflow-y: scroll;


  // box-shadow: 0 0 .1em rgba(0,0,0,.1);

  a:last-child li {
    border-bottom: none;
  }


  li {
    display: flex;
    align-items: center;
    padding: 1em;
    border-bottom: 1px solid rgba(0,0,0,.05);
    
  }

  li:hover {
    background: $blue;
    color: white;
    font-weight: bold;
  }
}



.skewed {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  height: 100vw;
  background: $blue; 
  top: -90vw;
  // position: relative;
  z-index: -1;
  transition: all 1s;
}
.skewed.start {
  top: -90vw;
  transform: skewY(-19deg);
  transform-origin: top left;
}

.bg-block {
  background: $blue;
  position: absolute;
  left: 0;
  right: 0;
  top: -600px;
  // opacity: 0;
  z-index: -1;
  height: 800px;
  transform: skewY(0deg);
  transform-origin: center;
  transition: all .75s;
}
.bg-block.start {
  top: -300px;
  transform: skewY(6deg);
  transform-origin: top left;
  // opacity: 1;
}



.really-narrow {
  max-width: 520px;
  margin: auto;
  padding: 0 2%;
}

.really-narrow-2 {
  max-width: 660px;
  margin: auto;
  padding: 0 2%;
}

.narrow {
  max-width: 1040px;
  margin: auto;
  padding: 0 2%;
}

.narrow-2 {
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 0 2%;
}

.bold {
  font-weight: bold;
}
.bold-900 {
  font-weight: 900;
}
.bold-100 {
  font-weight: 100;
}
.bold-200 {
  font-weight: 200;
}
.bold-300 {
  font-weight: 300;
}

.pointer {
  cursor: pointer;
}


.hidden {
  display: none;
}

.check-box {
  border: 2px solid rgba(0,0,0,.1);
  width: 1.5em;
  height: 1.5em;
  padding: 2px;
  border-radius: 50%;
  cursor: pointer; 

  .check {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background: $blue;
    opacity: 0;
  }
}
.check-box:hover, .check-box.checked:hover {
  border: 2px solid rgba(0,0,0,.15);
  .check {
    opacity: .25;
  }
}
.check-box.checked {
  .check {
    opacity: 1;
  }
}

.center {
  text-align: center;

}
.padding-bottom-200px {
  padding-bottom: 200px !important;
}
.padding-05, .padding-half {
  padding: .5rem;
}
.padding-1 {
  padding: 1rem;
}
.padding-2 {
  padding: 2rem;
}
.padding-3 {
  padding: 3rem;
}
.padding-4 {
  padding: 4rem;
}
.padding-top-05, .padding-top-half {
  padding-top: .5rem;
}
.padding-top-1 {
  padding-top: 1rem;
}
.padding-top-2 {
  padding-top: 2rem;
}
.padding-top-3 {
  padding-top: 3rem;
}
.padding-top-4 {
  padding-top: 4rem;
}
.padding-top-5 {
  padding-top: 5rem;
}

.padding-bottom-half, .padding-bottom-05 {
  padding-bottom: .5rem;
}
.padding-bottom-1 {
  padding-bottom: 1rem;
}
.padding-bottom-2 {
  padding-bottom: 2rem;
}
.padding-bottom-3 {
  padding-bottom: 3rem;
}
.padding-bottom-4 {
  padding-bottom: 4rem;
}
.padding-bottom-5 {
  padding-bottom: 5rem;
}

.padding-right-half, .padding-right-05 {
  padding-right: .5rem;
}
.padding-right-1 {
  padding-right: 1rem;
}
.padding-right-2 {
  padding-right: 2rem;
}
.padding-right-3 {
  padding-right: 3rem;
}
.padding-right-4 {
  padding-right: 4rem;
}
.padding-right-5 {
  padding-right: 5rem;
}

.padding-left-half, .padding-left-05 {
  padding-left: .5rem;
}
.padding-left-1 {
  padding-left: 1rem;
}
.padding-left-2 {
  padding-left: 2rem;
}
.padding-left-3 {
  padding-left: 3rem;
}
.padding-left-4 {
  padding-left: 4rem;
}
.padding-left-5 {
  padding-left: 5rem;
}

.margin-1 {
  margin: 1rem;
}
.margin-2 {
  margin: 2rem;
}
.margin-3 {
  margin: 3rem;
}

.margin-vertical-0, .no-margin-vertical {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.margin-vertical-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.margin-vertical-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.margin-left-05, .margin-left-half {
  margin-left: .5rem;
}

.margin-left-1 {
  margin-left: 1rem;
}
.margin-left-2 {
  margin-left: 2rem;
}
.margin-left-3 {
  margin-left: 3rem;
}
.margin-right-4px {
  margin-right: 4px;
}
.margin-right-half, .margin-left-05 {
  margin-right: .5rem;
}
.margin-right-1 {
  margin-right: 1rem;
}
.margin-right-2 {
  margin-right: 2rem;
}
.margin-right-3 {
  margin-right: 3rem;
}
.margin-right-4 {
  margin-right: 4rem;
}
.margin-bottom-4px {
  margin-bottom: 4px;
}
.margin-bottom-half, .margin-bottom-05 {
  margin-bottom: .5rem;
}
.margin-bottom-1 {
  margin-bottom: 1rem;
}
.margin-bottom-2 {
  margin-bottom: 2rem;
}
.margin-bottom-3 {
  margin-bottom: 3rem;
}
.margin-bottom-4 {
  margin-bottom: 4rem;
}
.margin-bottom-5 {
  margin-bottom: 5rem;
}
.margin-bottom-6 {
  margin-bottom: 6rem;
}
.margin-bottom-7 {
  margin-bottom: 7rem;
}
.margin-bottom-8 {
  margin-bottom: 8rem;
}
.margin-bottom-9 {
  margin-bottom: 9rem;
}

.margin-top-half {
  margin-top: 0.5rem;
}
.margin-top-05 {
  margin-top: 0.5rem;
}
.margin-top-1 {
  margin-top: 1rem;
}
.margin-top-2 {
  margin-top: 2rem;
}
.margin-top-3 {
  margin-top: 3rem;
}
.margin-top-4 {
  margin-top: 4rem;
}
.margin-top-5 {
  margin-top: 5rem;
}
.margin-top-6 {
  margin-top: 6rem;
}
.margin-top-7 {
  margin-top: 7rem;
}
.margin-top-8 {
  margin-top: 8rem;
}
.margin-top-9 {
  margin-top: 9rem;
}
.flex-cont {
  display: flex;
}
.flex-cont.column {
  flex-direction: column;
}

.flex-column {
  flex-direction: column;
}
.flex-cont.row {
  flex-direction: row;
}

.flex-row {
  flex-direction: row;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-baseline {
  align-items: baseline;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}

h1.no-margin-top, h2.no-margin-top {
  margin-top: 0;
}
h1.no-margin-bottom, h2.no-margin-bottom {
  margin-bottom: 0;
}

@keyframes logo-ani {
  0% {
    fill: magenta;
  }
  
  50% {
    fill: lime;
  }

  100% {
    fill: magenta;
  }
}

body {
  background: white;
}


.aaa {
  color: white;
  font-weight: 900;
  font-size: 2.5em;
}

// nav.main {
//   // background: transparent;
  
// }
nav.main.scrolled {
  box-shadow: 0 10px 20px rgba(0,0,0,.1);
  transition: box-shadow 1s;
}

nav.main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $top-nav-height;
  position: fixed;
  width: 100vw;
  z-index: 2000;
  background: #f9f9f9;
  // box-shadow: 0 .1em .1em #00000021;
  box-shadow: none;
  // padding: 0 1em;
  // background: white;

  transition: box-shadow 1s;

  .ham-menu-cont {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: all .33s;
    background: rgba(0,0,0,.5);
  }
  .ham-menu-cont.opened {
    pointer-events: all;
    opacity: 1;
  }

  .ham-menu {
    position: absolute;
    right: -100%;
    top: 0;
    width: 95vw;
    background-color: $blue;
    // display: none;
    height: 100vh;
    transition: all .33s;
    z-index: 999999;
    opacity: 0;
    display: flex;
    justify-content: flex-end;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: 1.5rem;
      margin-top: 4em;
      font-weight: 900;
      text-align: right;
      display: inline-flex;
      flex-direction: column;
      margin-right: 1em;

      li {
        border-radius: 99999px;
        transition: background .2s;
        margin-bottom: .5em;
        display: inline-block;
        
        a {
          color: white;
          display: inline-block;
          padding: .5em 1em;
          
        }
      }
      li:hover {
        background: $darkBlue;
      }
    }

    .ham-close {
      font-size: 1.25rem;
      height: 2em;
      width: 2em;
      display: flex;
      align-items: center;
      justify-content: center;

      color: white;
      border-radius: 50%;
      background: rgba(0,0,0,.05);
      right: 2rem;
      top: 1rem;
      position: absolute;
    }
  }

  .ham-menu.opened {
    // display: block;
    right: 0;
    // background-color: white;
    color: white;
    opacity: 1;
  }



  .ham-cont {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    padding-right: 1rem;

    i {
      font-size: 1.25rem;
      height: 2em;
      width: 2em;
      display: flex;
      align-items: center;
      justify-content: center;

      color: $darkBlue;
      border-radius: 50%;
      background: rgba(0,0,0,.05);
    }

    @include desktop {
      display: none;
    }
  }

 
  .logo-cont {

    svg, img {
      height: 3em;
      position: relative;
      // left: -16px;
      top: 3px;

      // @include mobile {
      //   height: 
      // }
    }

    path {
      // animation: logo-ani 5s infinite linear;
      // fill: black;
    }
  }
  .nav-links-cont {
    flex: 1;
    padding-left: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include mobile {
      display: none;
    }

    a.active.button.ghost:hover {
      // background: $blue;
      background: rgba(0,0,0,.1);
      // box-shadow: .2em .2em 0 rgba(0,0,0,.25);
      box-shadow: none;
    
      i {
        color: white;
      }
    }

    a.active {
      i {
        color: $blue;
      }

      .button.ghost:hover {
        // background: $blue;
        background: rgba(0,0,0,.1);
        // box-shadow: .2em .2em 0 rgba(0,0,0,.25);
        box-shadow: none;
      
        i {
          color: white;
        }
      }
    }
    a {
      i {
        color: rgba(0,0,0,.3);
      }
      i:hover {
        color: rgba(0,0,0,.4);
      }
    }


    a.text-link {
      padding: .66rem;
    }
    .button {
      margin-left: .5rem;
    }
  }
}

.text-right {
  text-align: right;
}
.nowrap {
  white-space: nowrap;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.spinner.absolute {
  position: absolute;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}
.button.wide {
  min-width: 10em;
}
.button, input[type=submit], button {
  white-space: nowrap;
  background: $blue;
  border-radius: 999px;
  // border: .15em solid black;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: .6em 1.1em;
  color: white;
  transition: all .2s;
  // line-height: 1;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  padding-left: 1.2em;
  border: none;
  // font-family: "Tofino Wide", sans-serif;
}

.button.danger.ghost {
  color: $red;
}
.button.danger.ghost:hover {
  background: $red;
  color: white;
}

.button.white-danger.ghost {
  color: white;
}
.button.white-danger.ghost:hover {
  background: white;
  color: $red;
}

.url {
  padding: .5em;
  background: rgba(0,0,0,.05);
  border-radius: 999px;
}

.button.danger {
  background: $red;
  color: white;
}
.button.danger:hover {
  background: darken($color: $red, $amount: 10);
}
button.red, .button.red {
  background: $red;
  border-color: $red;
}
.button.white, button.white {
  background: white;
  color: $blue;
}
.button.white:hover, button.white:hover {
  background: $darkBlue;
  color: white;
}
button.red:hover, .button.red:hover {
  background-color: white;
  color: $red;
  box-shadow: .2em .2em 0 $red;
}
button:hover, .button:hover, input[type=submit]:hover {
  background-color: $darkBlue;
  // transform: scale(1.025);
  // color: black;
  // box-shadow: .2em .2em .2em black;
}
button {
  font-size: 1rem;
}

.button.disabled, input[type=submit].disabled, button:disabled, button[type=submit]:disabled {
  pointer-events: none;
  opacity: .33;
}
button.outline.gold, .button.outline.gold  {
  color: $gold;
  border: 2px solid $gold;
}
button.uppy-FileInput-btn, button.outline, .button.outline {
  background: rgba(255,255,255,.1);
  transition: all 200ms;
  color: $darkBlue;
  border: 2px solid $darkBlue;
}
button.uppy-FileInput-btn:hover, button.outline:hover, .button.outline:hover {
  // background: $blue;
  background: rgba(0,0,0,.1);
  // box-shadow: .2em .2em 0 rgba(0,0,0,.25);
  box-shadow: none;

  i {
    //color: white;
  }
}

.button.ghost {
  background: none;
  transition: all 200ms;
  color: $darkBlue;
  border: none;
}
.button.ghost:hover {
  // background: $blue;
  background: rgba(0,0,0,.1);
  // box-shadow: .2em .2em 0 rgba(0,0,0,.25);
  box-shadow: none;

  i {
    //color: white;
  }
}

.button.toolbar.active {
  background: rgba(0,0,0,.1);
}

// *:empty::after {
//   content: ".";
//   visibility: hidden;
// }

a {
  color: rgba(0,0,0,1);
  text-decoration: none;
}

.card.selected {
  box-shadow: 0 0 0 .5em $blue !important;
  color: $blue;
}
.card.overflow-hidden {
  overflow: hidden;
}
.card.overflow-scroll {
  overflow: auto;
}
.overflow-scroll {
  overflow: auto;
}
.card {
  @include mobile {
    font-size: .8rem;
    padding: 1.5em;
  }


  padding: 2em;
  background: rgba(255,255,255,.75);
  transition: .2s all;
  position: relative;
  box-shadow: 0 0 .1em rgba(0,0,0,.1);
  border-radius: 2em;



  .bg-color {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(to bottom,rgba(0,0,0,.1) 0%, rgba(0,0,0,.66) 100%);
  }
}
.number {
  letter-spacing: -.033em;
}
.label {
  color: gray;
}
.gray {
  color: gray;
}
.green-text {
  color: rgb(40, 166, 26);
}
.lightgray {
  color: lightgray;
}
.blue {
  color: $blue;
}
.dark-blue {
  color: $darkBlue;
}
.blue-bg {
  background-color: $blue;
}
.dark-blue-bg {
  background-color: $darkBlue;
}
.spinner.dark-blue {
  border-left: .2em solid rgba($darkBlue, 1);
  border-top: .2em solid rgba($darkBlue, 1);
  border-right: .2em solid rgba($darkBlue, 1);
  border-bottom: .2em solid rgba(0,0,0, 0);
}
.gold {
  color: $gold;
}

.line-height-1 {
  line-height: 1;
}


.pulser {
	background: black;
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	// margin: 10px;
	height: 1em;
  width: 1em;
  // min-width: 1em;
  transform: scale(1);
  pointer-events: none;
	// animation: pulse-black 2s infinite;
}
.pulser.standby {
  background: rgba(0,0,0,.33);
  transform: scale(.5);
  // animation: none;
}


.pulser.red {
	background: rgba(255, 82, 82, 1);
	box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
	animation: pulse-red 2s infinite;
}
.pulser.blue {
	background: $blue;
	box-shadow: 0 0 0 0 $blue;
	animation: pulse-blue 2s infinite;
}

@keyframes pulse-blue {
	0% {
		transform: scale(0.7);
		box-shadow: 0 0 0 0 $blue;
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px transparentize($blue, 1);
	}
	
	100% {
		transform: scale(0.7);
		box-shadow: 0 0 0 0 transparentize($blue, 1);
	}
}

@keyframes pulse-red {
	0% {
		transform: scale(0.7);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}
	
	100% {
		transform: scale(0.7);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
}

.role-cont {
  padding: .2em .33em;
  border-radius: .2em;
  background: rgb(202, 202, 202);
  text-align: center;
  font-size: .75em;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
}
.role-cont.club-admin {
  background: rgb(255, 85, 85);
}
.role-cont.team-admin {
  background: rgb(255, 176, 85);
}
.role-cont.pe-teacher {
  background: rgb(176, 255, 85);
}
.role-cont.run-monitor {
  background: rgb(85, 164, 255);
}

.noti-bell-cont {
  position: relative;

  i {

  }

  .noti-count {
    position: absolute;
    right: -.5em;
    top: -.5em;
    // padding: 4px;
    // // background: red;
    // color: $blue;
    // font-weight: 900;
    // border-radius: 9999px;
  }
}
.transform-test {
  // -webkit-backface-visibility: hidden;
  // -moz-backface-visibility: hidden;
  // -webkit-transform: translate3d(0, 0, 0);
  // -moz-transform: translate3d(0, 0, 0);
  // backface-visibility: hidden;
  // transform: translate3d(0, 0, 0);

  // box-shadow: .2em .2em 2em rgba(0,0,0,.1);
  // transition: .2s all;
  // z-index: 1;
  // transform: scale(1.02);
}

.badge-modal-icon {
  filter: drop-shadow(0px 4px 8px rgba(0,0,0,.4));
  // position: absolute;
  top: 5vh;
  z-index: 9999;
}
.badge-modal-icon.small {
  top: 10vh;
}
.badge-modal-icon.medium {
}
.badge-modal-icon.large {
  top: 1.5vh
}

.badge2-cont {

  padding: 1em;

  @include mobile {
    padding: .5em;
  }

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  
  .badge2:hover {
    // transform: scale(1.2);
    position: relative;
    transform: scale(1.1) translateY(-.25em);
    filter: drop-shadow(0px 4px 4px rgba(0,0,0,.15));
    transition: .2s all;
  }

  .badge2.sidebar {
    height: 2em;
    margin-bottom: 0;
  }

  .badge2 {
    @include mobile {
      height: 3em;
    }


    display: inline-flex;
    margin-bottom: .5em;
    // margin: 6px 12px;
  
    // height: 50px;
    // width: 40px;
    height: 4em;
  
    align-items: center;
    justify-content: center;
  
    // -webkit-backface-visibility: hidden;
    // -moz-backface-visibility: hidden;
    // -webkit-transform: translate3d(0, 0, 0);
    // -moz-transform: translate3d(0, 0, 0);
    // backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    transition: .2s all;
    cursor: pointer;
    // transition: 1s all;
  
    img {
      height: 100%;
    }

    .count {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      flex-direction: row;
      font-size: 1rem;
      padding-left: .5em;
      padding-right: .5em;
      position: absolute;
      right: -1em;
      top: -1em;
      min-width: 20px;
      height: 1.5em;
      border-radius: 999px;
      box-shadow: 0px 2px 4px rgba(0,0,0,.33);

      i {
        font-size: 8px;
        color: rgba(0,0,0,.33);
      }
      .count-number {
        font-weight: 900;
        margin-left: 1px;
      }
    }
  
    
  }
}



.badge {
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;

  background: $darkBlue;
  color: $blue;

  margin: .1em;
  background: #ffcb00;
color: #ffef00;
margin: .25em;
// box-shadow: 0px 1px 2px #00000045;

}
.badge.red {
  background: #d23838;
  color: #ff9676;
}


.react-datepicker-popper {
  z-index:999999999 !important;
}

.card:last-of-type {
  border: none;
}
.card:not(.no-hover):not(.no-grow):hover {
  box-shadow: .2em .2em 2em rgba(0,0,0,.1);
  transition: .2s all;
  z-index: 1;
  transform: scale(1.02);
}
.card.no-grow:not(.no-hover):hover {
  box-shadow: .2em .2em 2em rgba(0,0,0,.1);
  transition: .2s all;
  z-index: 1;
}
.card.no-bg {
  background: none;
  box-shadow: none;
}

input.small, select.small {
  min-width: 5em !important;
  width: 5em !important;
}
input.medium, select.medium {
  min-width: 10em !important;
  width: 10em !important;
}
input.auto, select.auto {
  min-width: auto !important;
  width: auto !important;
  padding-right: 2em;
}

input[type=text].pledge {
  width: 3em;
  font-size: 1em;
  padding: .25em;
  text-align: center;
}
input[type=text].andy-input {
  width: 3em;
  font-size: 4em;
  padding: .25em;
  text-align: center;
  border: none;
  border-bottom: .1em solid rgba(0,0,0,.1);
}
input[type=text].andy-input:focus {
  border: none;
  border-bottom: .1em solid $blue;
}

.CardField, .ProseMirror, .editable-cont, input[type=date], input[type=time], input[type=number], input[type=text], textarea, input[type=password], .DayPickerInput input, select {
  border: 2px solid rgba(0,0,0,.1);
  padding: .7em;
  width: 100%;
  box-sizing: border-box;
  transition: all .2s;
  outline: none;
  min-width: 9rem;
  font-size: .9rem;
  align-self: flex-start;
}

.CardField.has-errors, .ProseMirror.has-errors, .editable-cont.has-errors, input[type=date].has-errors, input[type=time].has-errors, input[type=number].has-errors, input[type=text].has-errors, textarea.has-errors, input[type=password].has-errors, .DayPickerInput input.has-errors, select.has-errors {
  border: 2px solid $red;
}


.ProseMirror.left-padding, .editable-cont.left-padding, input[type=date].left-padding, input[type=time].left-padding, input[type=number].left-padding, input[type=text].left-padding, textarea.left-padding, input[type=password].left-padding, .DayPickerInput input.left-padding, select.left-padding {
  padding-left: 1.7em;
}
.ProseMirror.right-padding, .editable-cont.right-padding, input[type=date].right-padding, input[type=time].right-padding, input[type=number].right-padding, input[type=text].right-padding, textarea.right-padding, input[type=password].right-padding, .DayPickerInput input.right-padding, select.right-padding {
  // padding-right: 2.7em;
}
.left-padding-coin {
  padding-left: 2em !important;
}
select {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
	-moz-appearance: none; 
	-webkit-appearance: none; 
	appearance: none;
  background-color: white;
  border-radius: 0;
}

.ProseMirror, input[type=text]:focus, input[type=number]:focus, textarea:focus, input[type=password]:focus, .DayPickerInput input:focus, select:focus {
  border: 2px solid $blue;
}
option {
  border:none;
  outline:none;
}

fieldset {
  border:none;
  outline: none;
  // padding: 1em;
  // // background: rgba(0,0,0,.05);
  // margin-bottom: 1em;
}

input[type="submit"], button[type=submit] {
  font-size: 1em;
}

.field-error {

  color: $red;
  font-weight: bold;
  // padding: 1em 0;
  position: absolute;
  width: 100%;
}
.field-error.not-absolute {
  position: relative;
  padding: 1em 0;
}
form {
  position: relative;
}

// .spinner, .spinner:after {
//   border-radius: 50%;
//   width: 1em;
//   height: 1em;
// }

.club-avatar-cont {
  color: white;

  .avatar-name {
    text-shadow: 0 0 .5em black;
  }
  
}

.club-banner-cont {
  height: 300px;
  width: 100%;
  color: white;

  
}
.banner {
  background-size: cover;
  background-position: center center;
  // width: calc(100vw - $side-nav-width);
  position: absolute;
  top: $top-nav-height;
  left: $side-nav-width;
  right: 0;
  height: 300px;
  background-color: $darkBlue;
  // background-image: url("https://fyzeak.s3-us-west-1.amazonaws.com/120158874_3537678446289436_7216831924879481392_o.jpg");
  z-index: -1;

  .bg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, .66) 0%, rgba(0,0,0,0) 33%);
  }

  @include mobile {
    left: 0;
  }



  
}

.starburst {
  @include starburst(both,11,transparentize(gold, .7),white,1);
  
  width: 100%;
  height: 100%;
  animation: load8 10s infinite linear;
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  
  // box-shadow: 0 0 5px 10px #FFFFFF;
}

.spinner {
  // margin: 2em;
  font-size: 1em;
  position: relative;
  // text-indent: -9999em;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  border-left: .2em solid rgba($blue, 1);
  border-top: .2em solid rgba($blue, 1);
  border-right: .2em solid rgba($blue, 1);
  border-bottom: .2em solid rgba(0,0,0, 0);
  transform: translateZ(0);
  animation: load8 .65s infinite linear;
  display: inline-block;
  
}
.spinner.font-3 {
  font-size: 3em;
}

.button-spinner {
  position: absolute;
  border-left: .2em solid rgba(black, 1);
  border-top: .2em solid rgba(black, 1);
  border-right: .2em solid rgba(black, 1);
}


@keyframes load8 {
  0% {
    transform: rotate(0deg);
    
  }
  
  100% {
    transform: rotate(360deg);
  }
}

.DayPickerInput {
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}


input[type=checkbox], input[type=radio] {
  margin-right: 1em;
}

label.fit {
  flex: unset;
}
label.no-margin {
  margin: 0;
}

label.has-errors {
  span {
    color: $red;
  }
}

label {
  margin-bottom: 1.25em;
  padding-right: .5em;
  flex: 1 1;
  display: block;
  position: relative;

  span {
    text-transform: uppercase;
    font-size: .8em;
    color: rgba(0, 0, 0, 0.4);
    font-weight: 900;
    display: inline-block;
    padding-bottom: 6px;
  }
}
label:last-child {
  padding-right: 0;
}

table.basic {
  border-collapse: collapse;
  width: 100%;

  th {
    color: black;
    text-align: left;
    padding: .5em;
    border-bottom: 2px solid rgba(0,0,0,.1);
  }

  tr.low-key {
    th {
      font-size: 8px;
      color: rgba(0,0,0,.33);
      text-transform: uppercase;
      border-bottom: 2px solid rgba(0,0,0,.1);
      text-align: left;
    }
  }

  tr {

    th.header {
      text-align: left;
      font-weight: bold;
      font-size: 1.1em;
      padding: .5em 0;
      padding-top: 2em;
    }

    td {
      padding: .5em;
      border-bottom: 1px solid rgba(0,0,0,.1);
    }
    td.fit {
      width: 1%;
    }
  }

  tr:last-of-type {
    td {
      border-bottom: none;
    }
  }

  tbody:first-of-type {

    tr{
      th.header {
        padding-top: 0;
      }
    }
  }

}
.team-logo-cont {
  width: 3em;
  height: 3em;
  
  img {
    width: 100%;
  }
}
.team-logo.small {
  width: 1em;
  height: 1em;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: rgba(255,255,255,.5);
}
.avatar-cont.small {
  font-size: 2rem;
  width: 1em;
  height: 1em;
  box-shadow: none;
}
.avatar-cont.medium {
  font-size: 3rem;
  width: 1em;
  height: 1em;
  box-shadow: none;
}




.confetti-container {
  width: 100%;
height: 100%;
}
.confetti {
width: 15px;
height: 15px;
background-color: #f2d74e;
position: absolute;
left: 50%;
animation: confetti 5s ease-in-out -2s infinite;
transform-origin: left top;
z-index:999999999999999;
}
.confetti:nth-child(1) {
background-color: #f2d74e; left: 10%; animation-delay: 0;
}
.confetti:nth-child(2) {
background-color: #95c3de; left: 20%; animation-delay: -5s;
}
.confetti:nth-child(3) {
background-color: #ff9a91; left: 30%; animation-delay: -3s;
}
.confetti:nth-child(4) {
background-color: #f2d74e; left: 40%; animation-delay: -2.5s;
}
.confetti:nth-child(5) {
background-color: #95c3de; left: 50%; animation-delay: -4s;
}
.confetti:nth-child(6) {
background-color: #ff9a91; left: 60%; animation-delay: -6s;
}
.confetti:nth-child(7) {
background-color: #f2d74e; left: 70%; animation-delay: -1.5s;
}
.confetti:nth-child(8) {
background-color: #95c3de; left: 80%; animation-delay: -2s;
}
.confetti:nth-child(9) {
background-color: #ff9a91; left: 90%; animation-delay: -3.5s;
}
.confetti:nth-child(10) {
background-color: #f2d74e; left: 100%; animation-delay: -2.5s;
}

@keyframes confetti {
0% { transform: rotateZ(15deg) rotateY(0deg) translate(0,0); }
// 25% { transform: rotateZ(5deg) rotateY(360deg) translate(-5vw,20vh); }
// 50% { transform: rotateZ(15deg) rotateY(720deg) translate(5vw,60vh); }
// 75% { transform: rotateZ(5deg) rotateY(1080deg) translate(-10vw,80vh); }
100% { transform: rotateZ(15deg) rotateY(1440deg) translate(10vw,110vh); }
}


.opacity-1 {
  opacity: 0.1;
}
.opacity-25 {
  opacity: 0.25;
}


.opacity-5 {
  opacity: 0.5;
}
.opacity-75 {
  opacity: 0.75;
}

.side-nav.opened {
  @include mobile {
    top: 40%;
  }
  
}

.mobile-justify-center {
  @include mobile {
    justify-content: center;
  }
}

.mobile-center {
  @include mobile {
    text-align: center;
  }
}

.side-nav {

  @include desktop {

    background: linear-gradient(to bottom, #0000000a 0%, transparent 100%);
    width: $side-nav-width;
    padding-top: calc(#{$top-nav-height} / 4);
    height: calc(100vh - #{$top-nav-height});
    overflow-y: auto;
    display: inline-block;
    position: fixed;
    top: $top-nav-height;
    z-index: 1;
    
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      // padding: 0 .5em;

      li.apex {
        padding: .75em 1em;
        margin-bottom:.75em;
      }

      li {
        
        a.active {
          font-weight: bold;
          background: rgba(0,0,0,.05);
        }

        a, div.title {
          display: flex;
          padding: .75em;
          padding-left: 1em;
          color: $darkBlue;
          align-items: center;

          span {
            flex: 1;
          }
          i {
            width: 2em;
            
          }
        }

        div.title {
          
          font-weight:bold;
          color: rgba(0,0,0,.5);

          padding-left: 0;
          padding-right: 0;
          // margin-bottom: .5em;
          // padding: .75em 0;
        }
      }
      li:not(.apex):hover {
        background: rgba(0,0,0,.1);
      }
    }
  }

  @include mobile {

    div {
      // padding-bottom: 2em;
    }


    bottom: 60px;
    left: 0;
    right: 0;
    position: fixed;
    background: #fbfbfb;
    color: white;
    padding: 1rem;
    z-index: 3000;
    top: 100%;
    transition: all .2s;
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
    box-shadow: 0rem 0 2rem #00000054;
    overflow-y: auto;
  
    @include desktop {
      display: none;
    }
  
    ul {
      margin: 0;
      padding: 0;
  
      list-style: none;
  
      a.active {
        font-weight: bold;
        background: rgba(0,0,0,.05);
      }
  
      a, div.title {
        display: flex;
        padding: .75em;
        padding-left: 1em;
        align-items: center;
        color: $darkBlue;
        i {
          width: 2em;
          

          // div.ghj, div.pulser {
          //   margin-left: 4px;
          //   // width: .5rem;
          //   // height: .5rem;
          //   border-radius: 50%;
            
          // }
  
        }
      }
  
      li {
        // padding: 1rem;
        // border-bottom: 1px solid rgba(0,0,0,.075);
      }
      li:last-child {
        border: none;
      }
    }
  
  }

}



nav.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3001;
  font-size: 1.25rem;

  // border-top: 1px solid rgba(0,0,0,.05);
  box-shadow: 0px 0 6px rgba(0,0,0,.1);

  @include desktop {
    display: none;
  }
  
  .item {
    flex: 1;
    text-align: center;
    color: gray;
    // cursor: pointer;

    a {
      color: gray;
      padding: 1em;
    }
    a.active {
      color: $blue;
    }
  }
}
.bottom-menu-bg {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2999;
  background: rgba(0,0,0,.5);
  position: fixed;
  opacity: 0;
  transition: all .33s;
  pointer-events: none;
  cursor: pointer;  

  @include desktop {
    display: none;
  }
}
.bottom-menu-bg.opened {
  opacity: 1;
  pointer-events: all;
}


nav.main {

  .nav-submenu-cont {
    .nav-submenu-clicker {
      cursor: pointer;
      color: rgba(0,0,0,.3);
    }
    .nav-submenu-clicker:hover {
      color: rgba(0,0,0,.4);
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .nav-submenu {
      background: white none repeat scroll 0% 0%;
      position: absolute;
      top: 50px;
      right: 0px;
      padding: 1rem;
      border-radius: 1rem;
      // border-top-right-radius: 0;
      box-shadow: 0 10px 20px rgba(0,0,0,.1);
      padding-right: 2rem;
    }
    .nav-submenu:before {
      // content: '';
      // position: absolute;
      // width: 10px;
      // height: 10px;
      // background: white;
    }
  }
  .nav-submenu-cont.opened {
    .nav-submenu-clicker {
      color: $blue;
    }
  }



  .mobile-only {

    margin: 0 1rem;

    i {
      color: rgba(0,0,0,.3);
    }
    a.active {
      i {
        color: $blue;
      }
    }

    .mobile-nav-item {
      padding: .75rem;
      font-size: 1.25em;
      cursor: pointer;
    }

    .mobile-nav-left {
      flex: 1;
      display: flex;
      justify-content: flex-start;
    }

    .mobile-nav-logo-cont {
      img {
        height: 36px;
      }
    }
    .mobile-nav-right {
      flex: 1;
      display: flex;
      justify-content: flex-end;

    }

    
  }
}

.circle-number {
  background: transparentize($blue, .8);
  color: $blue;
  font-weight: 900;
  font-size: 1.75rem;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  // padding-right: .1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.steps-cont {
  .step:last-child, .step.current:last-child  {
    border-bottom: none;
  }
  .step.current {
    opacity: 1;
    pointer-events: all;
    border-bottom: 1px solid rgba(0,0,0,.1);
  }
  .step {
    margin-bottom: .5em;
    padding-bottom: .5em;
    border-bottom: 1px solid rgba(0,0,0,.1);

    opacity: .33;
    pointer-events: none;

    
  }
}


.uppy-file-select-cont {
  display: inline-block;
  position: relative;

  // width: 100px;
  // height: 30px;
  // background: $blue;
  // color: white;

  .uppy-DragDrop-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .uppy-DragDrop-inner {
    margin: 0;
    text-align: center;
    padding: 0;
    line-height: 1; 
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .hover-overlay {
    opacity: 0;
  }
}
.uppy-file-select-cont:hover {


  .hover-overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    font-weight:bold;

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.66);
    color: white;
    pointer-events: none;
    
  }

}