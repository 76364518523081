@import "styles/Constants.scss";


.modal-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.5);
  cursor: pointer;
  z-index: 10000;
}
.modal-bg.bg-blue {
  background: transparentize($color: $blue, $amount: .4);
}
.modal-cont {
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: center;
  z-index: 10001;

  .modal-content.large {
    width: 97vw;
    height: 97vh;
    max-width: 97vw;
    max-height: 97vh;
    top: calc(1.5vh + 3rem);
  }

  .modal-content.small {
    max-width: 500px;
    // top: 10%;
    top: calc(10vh + 3rem);
  }
  .modal-content.medium {

  }
  .modal-content.overflow-visible {
    overflow: visible;
  }
  .modal-content {
    // pointer-events: all;
    // position: relative;
    // background:white;
    // width: 97%;
    // max-width: 900px;
    // top: 10%;
    // top: calc(5vh + 3rem);
    // max-height: 90vh;
    // // overflow: auto;
    // overflow: auto;
    // border-radius: 1rem;

    pointer-events: all;
    position: relative;
    background: white;
    width: 97%;
    max-width: 900px;
    margin-top: 50px;
    margin-bottom: 50px;
    max-height: calc(100vh - 100px);
    overflow: auto;
    border-radius: 1rem;

    .modal-inner-content {
      // padding-bottom: 100px;
      
    }
    

    .close {
      // position: absolute;
      // right: 0; 
      // top: 0;
    }

    h1, h2 {
      margin-top: 0;
      margin-bottom: 0;
    }
  
  }
  
}
.modal-header{
  position: sticky;
  top: 0;
  z-index: 1;
  background:white;
  padding: 1em;
  left: 0;
  right: 0;

}
.modal-footer {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background: white;
  padding: 1em;
  left: 0;
  right: 0;
  // border-top: 1px solid rgba(0,0,0,.5);
  box-shadow: 0px -5px 10px rgba(0,0,0,.1);
}
.modal-controls {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 1em;
  background: white;
  right: 0;
}




