@import "styles/Constants.scss";

$coin-diameter: 7em;
$coin-thickness: .8em;
$coin-color: $gold;
// dime
// $coin-front: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Dime_Obverse_13.png/440px-Dime_Obverse_13.png";
// $coin-back: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/79/Dime_Reverse_13.png/440px-Dime_Reverse_13.png";
// penny
$coin-front: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/US_One_Cent_Obv.png/440px-US_One_Cent_Obv.png";
$coin-back: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/US_One_Cent_Rev.png/440px-US_One_Cent_Rev.png";
$edge-faces: 80;
$edge-face-length: 3.14*$coin-diameter/$edge-faces;
$turn-time: 3s;


.bottom-buttons-cont {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% - 1.75rem);
  // bottom: -1.75rem;


  .noti-button {
   
  }

  .dismiss-all {
    color: white;
  }

}

table.noti-award-list {
  border-collapse: collapse;
  min-width: 300px;
  // width: 100%;
  // text-align: left;

  tr {
    td {
      padding: .5em;
      border-bottom: 1px solid rgba(0,0,0,.05);
    }
  }
  tr:last-child {
    td {
      border-bottom: none;
    }
  }
}


.coinx, .badge-coin {
  position: absolute;
  width: $coin-diameter;
  height: $coin-diameter;
  // margin: 50px auto;
  transform-style: preserve-3d;
  // animation: shimmy $turn-time infinite linear;

  // animation: shimmy 5s infinite cubic-bezier(0.785, 0.135, 0.150, 0.860);

  animation: rotate3d $turn-time forwards cubic-bezier(0.230, 1.000, 0.320, 1.000);
  // animation-direction:
  // cubic-bezier(0.230, 1.000, 0.320, 1.000);
  // cubic-bezier(0.230, 1.000, 0.320, 1.000);
  // cubic-bezier(0.230, 1.000, 0.320, 1.000);
  // cubic-bezier(0.230, 1.000, 0.320, 1.000);
  animation-iteration-count: 1;
  transition: all .3s;
  z-index: 9999;
  top: calc(#{$coin-diameter} / -2);

  @for $i from 1 through $edge-faces {
    div.coin-edge:nth-child(#{$i}) {
      position: absolute;
      height: $edge-face-length + .2;
      width: $coin-thickness;

      background: darken( $coin-color, ( ($i - $edge-faces/2) * ($i - $edge-faces/2)) / ($edge-faces*$edge-faces/4) * 100 * 0.7 );
      transform: 
        translateY((#{($coin-diameter/2-$edge-face-length/2)-.1}))
        translateX(#{$coin-diameter/2-$coin-thickness/2})
        rotateZ(360deg/$edge-faces*$i+90)
        translateX(#{$coin-diameter/2})
        rotateY(90deg);
    }
  }

  // transform: perspective(1000px) rotateY(-45deg);
}

.coin__front,
.coin__back {
  position: absolute;
  width: $coin-diameter;
  height: $coin-diameter;
  border-radius: 50%;
  overflow: hidden;
  background-color: $coin-color;

  &:after {
    content: "";
    position: absolute;
    left: -$coin-diameter/2;
    bottom: 100%;
    display: block;
    height: $coin-diameter/1.5;
    width: $coin-diameter*2;
    background: #fff;
    opacity: 0.3;
    animation: shine $turn-time/2 forwards linear;
    // cubic-bezier(0.230, 1.000, 0.320, 1.000);
    animation-iteration-count: 2;
    // cubic-bezier(0.230, 1.000, 0.320, 1.000);
    // animation-iteration-count: 10;
  }
}

.coin__front {
  // background-image: url($coin-front);
  background-size: cover;
  transform: translateZ($coin-thickness/2);
}
.coin__back {
  // background-image: url($coin-back);
  background-size: cover;
  transform: translateZ(-$coin-thickness/2) rotateY(180deg);
}



.coin__shadow {
  position: absolute;
  width: $coin-diameter;
  height: $coin-thickness;
  border-radius: 50%;
  background: #000;
  box-shadow: 0 0 $coin-thickness*5 $coin-thickness*5 #000;
  opacity: 0.125;
  transform: rotateX(90deg) translateZ(-$coin-diameter*1.1) scale(.5);
}

@keyframes rotate3d {
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }

  100% {
    transform: perspective(1000px) rotateY(2160deg);
  }
}

@keyframes shimmy {
  0% {
    transform: perspective(1000px) rotateY(-15deg);
  }

  50% {
    transform: perspective(1000px) rotateY(15deg);
  }

  100% {
    transform: perspective(1000px) rotateY(-15deg);
  }
}

@keyframes shine {
  0%, 15% {
    transform: translateY($coin-diameter*2) rotate(-40deg);
  }
  50% {
    transform: translateY(-$coin-diameter) rotate(-40deg);
  }
  
}




@keyframes load9 {
  0% {
    opacity: 0;
    top: 0;
    transform: rotate(-10deg);

  }

  // 50% {
  //   opacity: 1;
  // }
  
  100% {
    opacity: 1;
    top: -1em;
    transform: rotate(0deg);
  }
}


.coins-icon {
  // position: absolute;
  // bottom: .5em;
  // left: -.5em;
}

.plus-cont {
  position: relative;
  top: 0;
  opacity: 0;
  // transition: 2s all;

  font-size: 5em;
  background: gold;
  color: white;
  text-shadow: none;
  padding: .25em;
  border-radius: 99999px;
  transform: rotate(-10deg);
}
.plus-cont.start {
  animation: load9 .6s 1 ease-in-out;
  opacity: 1;
  top: -1em;
  transform: rotate(0deg);

}

.noti-bg.start {
  opacity: 1;
}



.noti-bg {

  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: transparentize($blue, .1);
  // cursor: pointer;
  z-index: 20000;

  opacity: 0;

  // pointer-events: none;

  transition: opacity .2s;
}

.coins-added {
  transform: translateY(2em);
  opacity: 0;
  transition: all .33s;
}
.coins-added.started {
  transform: translateY(0);
  opacity: 1;
}

.noti-guard {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 19999;
  opacity: 0;
  pointer-events: none;

}
.noti-cont.start {
  transform: scale(1);
  opacity: 1;
  display: flex;
  pointer-events: all;

  .noti-content {
    box-shadow: 0 20px 50px #00000073;
  }
}


.noti-master {
  position: fixed;
  width: 100%;
  height: 100%;
  top: .33em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 20001;
  pointer-events: none;
}

.noti-cont {
  
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(.8);
  
  opacity: 0;
  pointer-events: none;
  width: 100%;
  transition: all .3s;

  .bottom {
    margin-top: 10em;
  }

  .starburst-cont {
    // font-size: 20rem;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    font-size: 20rem;
    height: 1em; 
    width: 1em;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    // padding: 100p
  
    top: -.33em;
    
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      font-size: 15rem;
    }
  
    img {
      width: 110%;
      position: absolute;
      z-index: 11;

      @include mobile {
        width: 110%;
      }
    }
    
  
    .feather {
      width: 100%;
      height: 100%;
      box-shadow: 0 0 .1em .1em #FFFFFF inset;
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      border-radius: 50%;
    }
  }


  .noti-content {
    pointer-events: all;
    position: relative;
    background: white;
    width: 95%;
    max-width: 700px;
    box-shadow: none;
    border-radius: 3rem;
    // margin-top: 100px;
    margin-bottom: 50px;



    @include mobile {
      border-radius: 1rem;
    }

    .noti-inner-content {
    
      max-height: calc(100vh - 200px);
      overflow: auto;

      .noti-body {
        margin-top: calc((#{$coin-diameter} / 2) + 2rem);
        margin-bottom: calc((#{$coin-diameter} / 2) + 0rem);

        .noti-title {
          font-size: 3.5rem;
          background: linear-gradient(34deg, #0093E9 0%, #80D0C7 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .noti-number {
        position: relative;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 999;
        text-shadow: .025em .025em 0 white;
        font-weight: 900;
        font-size: 4rem;
        letter-spacing: -.05em;

        @include mobile {
          font-size: 3rem;
        }
      }

      
    }
    

    .close {
      // position: absolute;
      // right: 0; 
      // top: 0;
    }

    h1, h2 {
      margin-top: 0;
      margin-bottom: 0;
    }
  
  }
  
}
.noti-header{
  position: sticky;
  top: 0;
  z-index: 1;
  background:white;
  padding: 1em;
  left: 0;
  right: 0;

}
.noti-footer {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background: white;
  padding: 1em;
  left: 0;
  right: 0;
  // border-top: 1px solid rgba(0,0,0,.5);
}
.noti-controls {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 1em;
  background: white;
  right: 0;
}

