@import "styles/Constants.scss";



main {
  padding-bottom: 0px;
}



.splash-cont {
  // width: 100%;
  display: flex;
  justify-content: center;
}

.splash {
  position: relative;
  width: 600px;
  max-width: 90vw;

  .arrow {
    // position: absolute;
    width: 100%;
    // left: 50%;
    // transform: translate(-50%, 0);
  }
  .dial {
    position: absolute;
    width: 1.5%;
    animation: load8 60s infinite linear;
    transform-origin: 50% 96.5%;
    //top: 24%;
    left: 49.5%;
    transform: translate(-50%, 0);
  }
}


.HomeScreen {
  // width: 100vw;
  // overflow: hidden;

  position: relative;
  top: -$top-nav-height;
  nav.main {
    background: #53b3d5;
  }
  // padding-bottom: 200px;

  .home-splash {
    position: relative;
    width: 100%;
    min-height: 800px;
    max-height: 900px;
    height: 90vh;
    background: $darkBlue;

    

  }

  .home-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
        background-position-x: center;
        background-position-y: top;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .9;
  }

  .home-banner-cont {
    
    padding: 0 150px;
    flex-wrap: wrap;
    padding-top: 200px;

    @include mobile {
      padding: 0 20px;
      padding-top: 150px;
    }
    
  }

  .home-button-cont {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.5em;
    padding-right: 50px;

    .button {
      // border-radius: 0;
      //transform: skewX(-10deg);
      background: white;
      transition: .2s all;
      color: $red;
      border: none;
      outline: none;
      // box-shadow: -.25em .25em .05em rgba(0,0,0,.3);
      font-weight: 900;
      font-family: 'Bebas Neue', sans-serif;
      font-size:1.25em;
      line-height:1;
    }
    .button:hover {
      transition: .2s all;
      color: white;
      background: $red;
      transform: scale(1.1);
    }

    @include mobile {
      padding-right: 0;
      justify-content: flex-start;
      margin-top: 50px;
    }
  }

  .home-banner {

    h1 {
      font-weight: 900;
      font-size: 7.5rem;
      line-height: .9;
      color: #fff;
      margin: 0;
      
      max-width: 4em;
      position: relative;
      // left: 15%;
      white-space: nowrap;
      text-transform: uppercase;
      //text-shadow: .02em .02em .002em #00000040;
      font-family: 'Bebas Neue', cursive;
      // transform: skewY(-4deg);
      //transform: rotate3d(10deg);
      //@include: perspective(800px) rotateY(20deg) scale(1);

      @include mobile {
        font-size: 5rem;
      }


      span {
        background: red;
        line-height: 1;
        height: 0.90em;
        display: inline-block;
        padding: 0 .25em;
        transform: skewX(-14deg);
      }
  
    }
  
  }

  .phone-cont {
    position: relative;
    width: 400px;
    // left: 50%;
    // transform: translateX(-50%);

    // padding: 0 50px;
    // top: -100px;
    // z-index: 10;n
    // right: 50%;
    // top: 150px;

    img {
      max-height: 47em;
      max-width: 80vw;
    }
  }

  .home-cont.video.slant:before {
    
    opacity: 0;
  }

  .home-cont.video.slant:after {
    position: absolute;
    width: 100%;
    height: calc(100% + 8vw);
    content: '';
    // background: linear-gradient(to bottom right, #252161 0%, #6983e8 100%);
    background: white;
    right: 0;
    // top: 8vw;
    transform-origin: top left;
    transform: skewY(-4deg);
    z-index: 1;
  }


  .home-cont.video {
    // background: #25224d;
    position: relative;

    height: 500px;
    max-height: calc(90vw * .5625);

    video {
      width: 900px;
      max-width: 90vw;
      position: relative;
      z-index: 101;
      box-shadow: 0 10px 50px rgba(0,0,0,.5);
      left: 50%;
      transform: translateX(-50%);
      top: calc(80px + 1vw);
      border-radius: 10px;

      position: absolute;
    }
  }

  .home-cont.below-video {
    // min-height: 2000px;

    padding-top: 100px;
    padding-bottom: 100px;

    @include mobile {
      padding-top: 50px;

      .phone-cont {
        // flex-direction: column;

        min-width: 50vw;
      }
    }

    p.copy {
      margin-top: 2em;
      font-size: 1.33rem;
      line-height: 1.5;
    }

    h1 {

      // text-align: left;
      font-size: 7rem;
      line-height: .9;
      white-space: nowrap;

      @include mobile {
        font-size: 4.5rem;
        margin-bottom: .5em;
        text-align: center;
      }
    }
  }

  .home-cont {
    position: relative;
    // min-height: 2000px;
    background: white;
    width: 100%;

    h1 {
      margin: 0;
    }


    
  }
  .home-cont.slant:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    background: inherit;
    right: 0;
    bottom: 0;
    transform-origin: top left;
    transform: skewY(-4deg);

  }
  
  .slant-content {
    position: relative;
    z-index: 10;
  }




  
  
}




.phone {
  opacity: 0;
  transform: scale(.9);
  transition: all .75s;
  display: block;
  position: relative;
  padding-top: 50px;
  width: 300px;
  right: 50px;
  // filter: drop-shadow(0 1rem 0.75rem rgba(0,0,0,.25));
}

.phone.slant {
  right: 0;
  opacity: 1;
  transform: perspective(800px) rotateY(-18deg) scale(1);
  
  
}

.global-skew {
  // transform: skewY(-9deg);
  // transform-origin: top left;
  

  transition: all .5s;
  right: 50px;
  opacity: 0;
  position: relative;



  h1 {
    font-size: 6rem;
  }

  @include mobile {
    transform: none;
    text-align: center;

    h1 {
      font-size: 5em;
    }
  }
}

.global-skew.start {
  right: 0;
  transform: perspective(800px) rotateY(-18deg) scale(1);
  opacity: 1;

  @include mobile {
    transform: none;
  }
}